import React, { useState } from 'react'
import classes from './LandingPage.module.css'
import { Spin } from "antd";
import {Link} from "react-router-dom"

const LandingPage = () => {
    const [test, setTest] = useState(false);
    const [test1, setTest1] = useState(false);
    return <div className={classes.CoverArea}>
        {/* <div className={classes.Logo}>
                <img height="100%" src="https://github.com/KSHITIJ-2022/media/blob/master/images/ILU-light-new.png?raw=true"></img>
            </div> */}
        {/* <Spin spinning={(!test && !test1)} className={classes.pos} style={{ height: "100vh" }}> */}
            <div className={classes.slot}>
                <div className={classes.lowInp}>
                    <button><Link className={classes.lin} to="/questions">Enter</Link></button>
                </div>
            </div>
        {/* </Spin> */}
    </div>;
}

export default LandingPage;