import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Rules from './Components/Rules/Rules'
import Questions from './Components/Questions/Questions'
import LandingPage from './Components/LandingPage/LandingPage'
import LoginPage from './Components/LoginPage/LoginPage'
import Navbar from './Components/Navbar/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { checkUserAuth } from './redux/login/loginThunk'
import { Navigate } from 'react-router-dom'
import 'antd/dist/antd.css';
import magic from "./Components/sound/fuck.mp3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import Admin from './Components/Admin/Admin';

function App() {
  const [audioStatus, changeAudioStatus] = useState(false);
  const [first, setFirst] = useState(true)
  const myRef = useRef();
  const FirstTime = () => {
    if (first) {
      startAudio();
      window.removeEventListener("click", FirstTime);
    }
  }

  const startAudio = () => {
    if (myRef.current) {
      myRef.current.loop = true;
      myRef.current.play();
      changeAudioStatus(true);
      setFirst(false);
    }
  };

  const pauseAudio = () => {
    // console.log("here");
    if (myRef.current) {
      myRef.current.pause();
      changeAudioStatus(false);
    }
    changeAudioStatus(false);
  };

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkUserAuth())
    window.addEventListener("click", FirstTime)
  }, [])

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={
          <>
            <LandingPage />
            <audio
              ref={myRef}
              src={magic}
            />
            <FontAwesomeIcon icon={audioStatus == false ? faVolumeMute : faVolumeUp} className="audioIcon2" onClick={e => {
              if (audioStatus == false) { startAudio(); }
              else {
                pauseAudio();
              }
            }} />
          </>
        } />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path='/rules' element={
          <>
            <Rules />
            <audio
              ref={myRef}
              src={magic}
            />
            <FontAwesomeIcon icon={audioStatus == false ? faVolumeMute : faVolumeUp} className="audioIcon2" onClick={e => {
              if (audioStatus == false) { startAudio(); }
              else {
                pauseAudio();
              }
            }} />
          </>
        } />
        <Route exact path="/questions" element={isAuthenticated ? 
        <>
        <Questions pauseAudio={pauseAudio} />
        </>
        : <Navigate to="/login" />} />
        <Route exact path="/login" element={
          !isAuthenticated ?
          <>
          <LoginPage pauseAudio={pauseAudio} /> 
          <audio
              ref={myRef}
              src={magic}
            />
            <FontAwesomeIcon icon={audioStatus == false ? faVolumeMute : faVolumeUp} className="audioIcon2" onClick={e => {
              if (audioStatus == false) { startAudio(); }
              else {
                pauseAudio();
              }
            }} />
          </>
          : 
          <>
          <Navigate to="/questions" />
          </>
          } />
      </Routes>
    </Router>
  );
}

export default App;
