import React, { useState, useEffect } from 'react'
import classes from "./admin.module.css";
import { useSelector } from "react-redux";
import API from '../../api';
import { DatePicker,Card, Row, Col, Button, Form, Input, Radio, message } from 'antd';
import { number } from 'prop-types';

function Admin() {

  const [form] = Form.useForm();
  const FormItem = Form.Item;
  const token = useSelector((state) => state.auth.token);

  const handleFormSubmit = (values) => {
    const points = parseInt(values.points);
    console.log(typeof(points))

    if (typeof(points) != "number" || values.hint?.length == 0 || values.answer?.length == 0 ) {
      // console.log(values,"==");
      // console.log(values.hint?.length,"==", values.hint?.length,"==",audio_url[0]=='',"=",image_url[0]=='');
      return;
    }

    API.post("/question/create_question", { token, values })
      .then((res) => {
        console.log(res);
        if (res.status == 200)
          message.success("Added");
      })
      .catch((err) => {
        console.log(err);
        // setLeaderboardLoading(false);
        message.error("Not Added");
      });

  };

  return (
    <div className={classes.RulesMain}>
      <div className={classes.CoverArea}>
        {/* <Row gutter={[16, 24]}> */}
        {/* <Col span={24}> */}
        <Card
      title="Add question"
      style={{
        width: 300,
      }}
    >
        <Form onFinish={(values) => handleFormSubmit(values)}>

              <FormItem label="Hint" name="hint" >
                <Input placeholder="hint content" />
              </FormItem>

              <FormItem label="Image Urls" name="image_url">
                <Input placeholder="URLs" />
              </FormItem>
              <FormItem label="Audio Urls" name="audio_url">
                <Input placeholder="URLs" />
              </FormItem>
              <FormItem label="Points" name="points">
                <Input placeholder="Points here" type='numeric' />
              </FormItem>
              <FormItem label="Answer" name="answer">
                <Input placeholder="ans here" />
              </FormItem>
              <FormItem >
                <Button type="primary" htmlType="submit">Submit</Button>
              </FormItem>

        </Form>
        </Card>
        {/* </Col> */}
        {/* </Row> */}
      </div>
    </div>
  )
}

export default Admin